import {
  type AnyAction,
  createSlice,
  type PayloadAction,
  type ThunkDispatch,
} from "@reduxjs/toolkit";
import type { CurrencyType } from "types/BetTypes";
import type { PromotionClaim } from "types/PromotionTypes";
import {
  message,
  watch,
  type Result,
  type ResultError,
} from "utilities/message";
import { requestStarted, requestFinished } from "utilities/Loading/Actions";
import { convertAmountToCents } from "common";

import { GROUP_WALLET } from "utilities/Loading/Constants";

import { collection, onSnapshot } from "firebase/firestore";

import { convertErrorToToast, hasErrors } from "utilities/errorsParser";

import { navigate } from "library/components/Link/Link";

import { extractPendingDepositBonusClaim } from "types/PromotionTypes";
import appConfig from "appConfig";
import { CARD_PROCESSED } from "sections/ProcessMw";

import { cancelTransaction, processWithdraw } from "utilities/api/paymentIQ";
import { getFirestore } from "store/getFirebase";
import { toast } from "hooks/ui/useToast";
import type { RootState } from "store/createStore";
import { exp } from "mathjs";
import {
  sendAppMessage,
  WEB_TO_APP_MESSAGE_TYPES,
} from "utilities/AppWebMessagingBridge";
import { isAndroidOrIOSWebView } from "utilities/display";

export type BonusState = {
  code: string;
  currency: string;
  active: boolean; // TODO: Is tracking this redundant now?
  amount: number;
};

const initialState = {
  bonusCode: "" as string, // User data
  bonusState: null as BonusState, // Data from server
  appleDepositStatus: "ready" as "success" | "ready",
  googleDepositStatus: "ready" as "success" | "processing" | "ready",
  bankAccountLoading: false, // loading state for adding or removing bank accounts
  appGooglePayAvailable: false,
  isCheckingGooglePayAvailable: true,
  appApplePayAvailable: false,
  isCheckingApplePayAvailable: true,
  isManual3ds: false,
};

export type WalletState = typeof initialState;

export type DepositOutcome = "success" | "failure";

let addedCardId: string;

const walletSlice = createSlice({
  name: "wallet",
  initialState: initialState as WalletState,
  reducers: {
    updateBonusCode(state: WalletState, action: PayloadAction<string>) {
      state.bonusCode = action.payload.toUpperCase();
    },
    setBonusState(state: WalletState, action: PayloadAction<BonusState>) {
      state.bonusState = action.payload;
    },
    setAppleDepositStatus(
      state: WalletState,
      action: PayloadAction<"success" | "ready">,
    ) {
      state.appleDepositStatus = action.payload;
    },
    setGoogleDepositStatus(
      state: WalletState,
      action: PayloadAction<"success" | "processing" | "ready">,
    ) {
      state.googleDepositStatus = action.payload;
    },
    setIsGooglePayAvailable(
      state: WalletState,
      action: PayloadAction<boolean>,
    ) {
      state.appGooglePayAvailable = action.payload;
      state.isCheckingGooglePayAvailable = false;
    },
    setIsApplePayAvailable(state: WalletState, action: PayloadAction<boolean>) {
      state.appApplePayAvailable = action.payload;
      state.isCheckingApplePayAvailable = false;
    },
    // events used for recording analytics only events
    /* eslint-disable */

    setSkrAccount(state: WalletState) {
      // analytics
    },
    addCashCardAttempt(state: WalletState, action?: PayloadAction<string>) {
      // analytics
    },
    addPicklebetCardAttempt(
      state: WalletState,
      action?: PayloadAction<string>,
    ) {
      // analytics
    },
    removeCashCardAttempt(state: WalletState) {
      // analytics
    },
    removePicklebetCardAttempt(state: WalletState) {
      // analytics
    },
    depositFundsAttempt(
      state: WalletState,
      action?: PayloadAction<{
        currency: string | CurrencyType;
        method: string;
        amount: number;
        outcome?: string;
        promotion?: string;
        promotionCode?: any;
      }>,
    ) {
      // analytics
    },
    depositFundsFailure(
      state: WalletState,
      action?: PayloadAction<{
        currency: string | CurrencyType;
        method: string;
        amount: number;
        outcome?: string;
        promotion?: string;
        promotionCode?: any;
      }>,
    ) {
      // analytics
    },
    firstTimeDeposit(
      state: WalletState,
      action?: PayloadAction<{
        currency?: string | CurrencyType;
        method: string;
        amount?: number;
        outcome?: string;
        promotion?: string;
        promotionCode?: any;
      }>,
    ) {
      // analytics
    },
    openManual3ds(state: WalletState) {
      state.isManual3ds = true;
    },
    toggleManual3ds(state: WalletState) {
      state.isManual3ds = !state.isManual3ds;
    },
    closeManual3ds(state: WalletState) {
      state.isManual3ds = false;
    },
    attemptRemoveBankAccount(state) {
      //analytics
    },
    withdrawCashAttempt(
      state,
      action: PayloadAction<{
        currency: string;
        amount: number;
        outcome?: string;
      }>,
    ) {
      // analytics
    },
    addedBankDetails(state, action: PayloadAction<{ outcome: string }>) {
      // analytics
    },
    buySkrAttempt(state: WalletState) {
      // analytics
    },
    attemptRegisterAddress(state: WalletState) {
      // analytics
    },
    cancelWithdrawal(
      state: WalletState,
      action: PayloadAction<{ currency: string; amount: number }>,
    ) {
      // analytics
    },
    setBankLoading(state, action) {
      state.bankAccountLoading = action.payload;
    },
    /* eslint-enable */
  },
});

// private functions
const beautifyMerchantWarriorError = (message) => {
  if (message.includes("MW - 007")) {
    return "You've entered an invalid card number";
  }

  if (message.includes("MW - 068")) {
    return "Credit cards are prohibited by law. Please add a debit card, or deposit with PayID";
  }

  return "Something went wrong, please contact support";
};

export const handle3dsChallengeIframe = (creq: string, acsUrl: string) => {
  const form = document.createElement("form");
  form.setAttribute("method", "POST");
  form.setAttribute("action", acsUrl);
  form.setAttribute("target", "mw3dsFrame");
  form.setAttribute("sandbox", "allow-forms allow-same-origin allow-scripts");

  const creqInputField = document.createElement("input");
  creqInputField.setAttribute("type", "hidden");
  creqInputField.setAttribute("name", "creq");
  creqInputField.setAttribute("value", creq);
  form.appendChild(creqInputField);
  document.body.appendChild(form);

  form.submit();
};

const post3dsChallenge = (creq: string, acsUrl: string) => {
  // check if we are in the apps
  if (isAndroidOrIOSWebView()) {
    // we send a message to add to the whitelist
    // rename this to post 3ds challenge
    sendAppMessage(WEB_TO_APP_MESSAGE_TYPES.APP_MESSAGE_MW3DS_CHALLENGE, {
      creq, // send it back for post3dsChallengeIframe
      acsUrl, // add to whitelist
    });
    // Then we listen for a message from the app and display the iframe in AppToWebEvents.tsx
    return;
  }

  // do it now
  handle3dsChallengeIframe(creq, acsUrl);
};

export const handlePostMwFingerPrintIframe = (
  paReq: string,
  acsUrl: string,
) => {
  const form = document.createElement("form");
  form.setAttribute("method", "POST");
  form.setAttribute("action", acsUrl);
  form.setAttribute("target", "mw3dsFrame");
  form.setAttribute("sandbox", "allow-forms allow-same-origin allow-scripts");

  const inputField = document.createElement("input");
  inputField.setAttribute("type", "hidden");
  inputField.setAttribute("name", "threeDSMethodData");
  inputField.setAttribute("value", paReq);
  form.appendChild(inputField);

  document.body.appendChild(form);
  form.submit();
};

const postMwFingerPrint = (paReq: string, acsUrl: string) => {
  // check if we are in the apps
  if (isAndroidOrIOSWebView()) {
    // we send a message to add to the whitelist
    sendAppMessage(WEB_TO_APP_MESSAGE_TYPES.APP_MESSAGE_MW3DS_FINGERPRINT, {
      paReq,
      acsUrl,
    });
    // Then we listen for a message from the app and display the iframe in AppToWebEvents.tsx
    return;
  }

  //handle it now
  handlePostMwFingerPrintIframe(paReq, acsUrl);
};

/**
 * Generate dynamic merchant warrior form and submit it
 * @param payload
 */
const postMwForm = (payload) => {
  const form = document.createElement("form");
  form.setAttribute("method", "POST");
  form.setAttribute("action", payload.addCardUrl);
  form.setAttribute("target", "mwFrame");
  form.setAttribute("sandbox", "allow-forms allow-same-origin allow-scripts");
  for (const key in payload) {
    if (payload.hasOwnProperty(key)) {
      const hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key);
      hiddenField.setAttribute("value", payload[key]);
      form.appendChild(hiddenField);
    }
  }
  document.body.appendChild(form);
  form.submit();
};

// Thunks

export const doSkrillDeposit =
  (
    amount: number,
    promotionCode: any,
    callback: () => void,
    currency: string | CurrencyType,
  ) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    const depositCents = convertAmountToCents(amount);

    // form return url
    const returnUrl = new URL(window.location.href);
    returnUrl.searchParams.set("return", "true");
    returnUrl.searchParams.set("transactionId", "{transactionId}");

    // form cancel url
    const cancelUrl = new URL(window.location.href);
    cancelUrl.searchParams.set("cancel", "true");
    cancelUrl.searchParams.set("transactionId", "{transactionId}");

    const [response, error] = await message("skrill-deposit", {
      amount: depositCents,
      returnUrl: returnUrl.toString(),
      returnUrlText: "Back to Picklebet",
      cancelUrl: cancelUrl.toString(),
      logoUrl: new URL(
        "/images/logo-small.png",
        window.location.href,
      ).toString(),
    });

    const sid = response?.data?.sid;

    if (error || !sid) {
      toast(convertErrorToToast(error, { title: "Unable to deposit funds!" }));
      dispatch(
        depositFundsAttempt({
          currency,
          method: "skrill",
          amount: depositCents / 100,
          outcome: "failure",
        }),
      );

      return;
    }

    dispatch(
      depositFundsAttempt({
        currency,
        method: "skrill",
        amount: depositCents / 100,
        outcome: "success",
        promotion: !!promotionCode ? "Yes" : "No",
        promotionCode: promotionCode,
      }),
    );

    // wait one second to send data and then redirect user to Skrill
    setTimeout(() => {
      window.location.replace(`https://pay.skrill.com/app/?sid=${sid}`);
    }, 1000);

    if (callback && callback instanceof Function) {
      callback();
    }

    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  };

export type DepositOutcomeTypes = "success" | "failure";

const getBrowserInfo = () => {
  if (
    typeof window === "undefined" ||
    typeof navigator === "undefined" ||
    typeof screen === "undefined"
  ) {
    // do nothing for SSR
    return null;
  }

  return {
    browserJavascriptEnabled: true, // This will always be true if this script runs
    browserJavaEnabled: navigator.javaEnabled(),
    browserColorDepth: screen.colorDepth.toString(),
    browserLanguage: navigator.language || (navigator as any).userLanguage,
    browserScreenHeight: screen.height.toString(),
    browserScreenWidth: screen.width.toString(),
    browserTZ: new Date().getTimezoneOffset().toString(),
    browserUserAgent: navigator.userAgent,
  };
};

export const doMWDeposit =
  (
    cardId: string | null,
    csc: any,
    digitalWalletToken: string | null = null,
    amount: string | number,
    promotionCode = "",
    digitalWalletType: "Google" | "Apple" | undefined,
  ) =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<DepositOutcome> => {
    const depositCents = convertAmountToCents(amount);

    window.addEventListener("message", (event) => {
      if (event.data?.type === "mw-challenge" && event.data?.done) {
        dispatch(closeManual3ds());
      }
    });

    const [watcher, error] = await watch(
      "mw-deposit-funds-3ds",
      digitalWalletToken
        ? {
            amount: depositCents,
            digitalWalletToken,
            digitalWalletType,
            browserInfo: getBrowserInfo(),
          }
        : {
            amount: depositCents,
            csc,
            cardId,
            browserInfo: getBrowserInfo(),
          },
      {
        timeOutMs: 120 * 1000, // 120s time out on requests
      },
    );

    function handleError(error: ResultError) {
      toast(convertErrorToToast(error, { title: "Unable to deposit funds!" }));
      dispatch(
        depositFundsFailure({
          currency: "AUD",
          method: "card",
          amount: depositCents / 100,
          outcome: "failure",
        }),
      );

      if (error.code === "threeds-enrollment-failed") {
        navigate("/wallet/withdraw");
      }
    }

    async function handleResponse(result: Result): Promise<DepositOutcome> {
      const [response, error] = result;
      if (error) {
        handleError(error);
        dispatch(closeManual3ds());
        return "failure";
      }

      switch (response.code) {
        case "3ds-fingerprint-required":
          postMwFingerPrint(
            response?.data?.["pa-req"],
            response?.data?.["acs-url"],
          );
          const fingerPrintResponse = await watcher.next();
          return await handleResponse(fingerPrintResponse);
        case "3ds-challenge-required":
          post3dsChallenge(
            response?.data?.["challenge-data"],
            response?.data?.["acs-url"],
          );

          const iframe = document.getElementById(
            "mw3dsFrame",
          ) as HTMLIFrameElement;

          iframe.onload = () => {
            dispatch(openManual3ds());
          };

          const challengeResponse = await watcher.next();
          return await handleResponse(challengeResponse);
        case "funds-deposited":
          dispatch(
            depositFundsAttempt({
              currency: "AUD",
              method: "card",
              amount: depositCents / 100,
              outcome: "success",
              promotion: !!promotionCode ? "Yes" : "No",
              promotionCode: promotionCode,
            }),
          );
          // TODO: Probably need a better check here
          if (String(response.data.firstTime) === "true") {
            dispatch(
              firstTimeDeposit({
                currency: "AUD",
                method: "card",
                amount: depositCents / 100,
              }),
            );
          }
          dispatch(closeManual3ds());
          return "success";
        default:
          handleError(new Error(response.displayText));
          return "failure";
      }
    }

    if (error) {
      handleError(error);
      return;
    }

    const response = await watcher.next();

    return await handleResponse(response);
  };

export const doAddBank =
  (name: string, bsb: string, number: string) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(setBankLoading(true));

    const [_, error] = await message("create-au-bank-account", {
      name,
      bsb,
      number,
    });

    if (error) {
      dispatch(
        addedBankDetails({
          outcome: "success",
        }),
      );

      toast(
        convertErrorToToast(error, {
          title: "Unable to add bank details!",
        }),
      );
      return;
    }

    dispatch(
      addedBankDetails({
        outcome: "success",
      }),
    );
    toast({
      title: "Success!",
      variant: "success",
      description: "Your bank details were added successfully.",
    });
    dispatch(setBankLoading(false));
  };

export const doCancelWithdrawal =
  ({ id, withdrawal }, callback?: () => void) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    const [_, error] = await message("cancel-withdrawal", id);

    if (error) {
      toast(
        convertErrorToToast(error, {
          title: "Unable to cancel withdrawal!",
        }),
      );
      return;
    }

    dispatch(
      cancelWithdrawal({
        amount: withdrawal.amount / 100,
        currency: withdrawal.currency,
      }),
    );
    toast({
      title: "Success!",
      variant: "success",
      description: "Your withdrawal was cancelled.",
    });

    if (callback && callback instanceof Function) {
      callback();
    }
  };

export const cancelPaymentIQTransaction =
  async (
    transaction: {
      id: any;
      createdAt?: Date;
      amount: any;
      currency: any;
      status?: string;
    },
    callback: { (): void; (): void },
  ) =>
  async (dispatch, getState) => {
    const {
      auth: { sessionId, userId },
    } = getState();

    const requestId = "cancelWithdrawal";
    dispatch(requestStarted(GROUP_WALLET, requestId));

    try {
      const cancelled = await cancelTransaction(
        userId,
        sessionId,
        transaction.id,
      );

      if (!cancelled) {
        throw new Error("Unable to cancel withdrawal!");
      }

      dispatch(
        cancelWithdrawal({
          amount: transaction.amount / 100,
          currency: transaction.currency,
        }),
      );

      toast({
        title: "Success!",
        variant: "success",
        description: "Your withdrawal was cancelled.",
      });
    } catch (error) {
      toast(
        convertErrorToToast(error, {
          title: "Unable to cancel withdrawal!",
        }),
      );
    } finally {
      if (callback && callback instanceof Function) {
        callback();
      }
    }

    dispatch(requestFinished(requestId));
  };

export const doWithdrawCash =
  (
    payload: {
      amount: number;
      currency: string;
      bankAccountId: string;
      externalType: string;
    },
    email: string,
  ) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    const [response, error] = await message("create-withdrawal", payload);

    if (error) {
      toast(
        convertErrorToToast(error, {
          title: "Unable to withdraw funds!",
        }),
      );
      dispatch(
        withdrawCashAttempt({
          currency: payload.currency,
          amount: payload.amount / 100,
          outcome: "failure",
        }),
      );
      return;
    }

    toast({
      title: "Success!",
      variant: "success",
      description:
        "Withdrawals submitted by 5pm AEST, will ordinarily be processed by 6pm AEST on the same business day.",
    });
    dispatch(
      withdrawCashAttempt({
        currency: payload.currency,
        amount: payload.amount / 100,
        outcome: "success",
      }),
    );
  };

export const doAttemptRemoveBankAccount = (outcome) => ({
  type: attemptRemoveBankAccount,
  payload: { outcome },
});

export const doRemoveBankAccount =
  (callback: () => void) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(setBankLoading(true));

    const [_, error] = await message("remove-bank-account");

    if (error) {
      dispatch(doAttemptRemoveBankAccount("failure"));
      return;
    }

    dispatch(doAttemptRemoveBankAccount("success"));

    if (callback && callback instanceof Function) callback();
    dispatch(setBankLoading(false));
  };

export const doAddCard =
  (
    data: { name: any; number: any; expiry: any },
    callback: (addedCardId: string) => void,
    onCardAdded: (cardId: string) => void,
  ) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    const expiryMonth = data.expiry.slice(0, 2);
    const expiryYear = data.expiry.slice(-2);

    const [response, error] = await message("mw-get-add-card-access-token", {
      numberFirst: data.number.slice(0, 4),
      numberLast: data.number.slice(-4),
      name: data.name,
      expiryMonth,
      expiryYear,
      returnUrl: "",
    });

    if (error) {
      dispatch(doShowError(error, "cash"));
      return;
    }

    const mwClient = await window.MerchantWarrior.createWithToken(
      response.data?.accessToken,
      {},
    );

    mwClient.directRequest({
      method: "addCard",
      cardName: data.name,
      cardNumber: data.number,
      cardExpiryMonth: expiryMonth,
      cardExpiryYear: expiryYear,
    });

    const cardAddedPromise: Promise<{
      cardID: string;
    }> = new Promise((resolve, reject) => {
      mwClient.on(
        "card-added",
        (isSuccess: boolean, response: { cardID: string }) => {
          if (isSuccess) {
            resolve(response);
          } else {
            reject(response);
          }
        },
      );
    });

    try {
      const response = await cardAddedPromise;
      await dispatch(
        doConfirmCard(response?.cardID, "cash", () => onCardAdded(addedCardId)),
      );
    } catch (error) {
      dispatch(doShowError(error?.responseMessage, "cash"));
      return;
    }

    if (callback && callback instanceof Function) {
      callback(addedCardId);
    }
  };

export const doConfirmCard =
  (cardId: string, source: string, onConfirm: () => void) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    const [_, error] = await message("mw-confirm-card", {
      cardId,
    });

    if (error) {
      console.error("Confirm Card Error", error);
      toast(convertErrorToToast(error, { title: "Unable to add a card!" }));

      // trigger segment hooks
      if (source === "cash") {
        dispatch(addCashCardAttempt("failure"));
      } else {
        dispatch(addPicklebetCardAttempt("failure"));
      }

      return;
    }

    // trigger segment hooks
    if (source === "cash") {
      dispatch(addCashCardAttempt("success"));
    } else {
      dispatch(addPicklebetCardAttempt("success"));
    }

    onConfirm && onConfirm();

    // get rid of the query string
    navigate(window.location.pathname + window.location.hash);
  };

export const doShowError = (message, source) => (dispatch) => {
  toast({
    title: "Unable to add a card!",
    variant: "danger",
    description: beautifyMerchantWarriorError(message),
  });
  if (source === "cash") {
    dispatch(addCashCardAttempt("failure"));
  } else {
    dispatch(addPicklebetCardAttempt("failure"));
  }
};

export const doVerifyCard = (id: string, code: string) => async () => {
  const [response, error] = await message("verify-card", {
    code,
    id,
  });

  if (error) {
    toast(convertErrorToToast(error, { title: "Unable to verify card!" }));
    return;
  }

  toast({
    title: "Success!",
    variant: "success",
    description: response.displayText,
  });
};

export const doBeginVerification =
  ({ id, callback }: { id: string; callback?: () => void }) =>
  async () => {
    const [response, error] = await message("mw-begin-verification", {
      id,
    });

    if (error) {
      toast(convertErrorToToast(error, { title: "Unable to verify!" }));
      return;
    }

    toast({
      title: "Success!",
      variant: "success",
      description: response.displayText,
    });

    if (callback && callback instanceof Function) {
      callback();
    }
  };

export const doRemoveCard =
  (id: any, source: string) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    const [_, error] = await message("mw-remove-card", {
      id,
    });

    if (error) {
      console.error("Error removing card", error);
      return;
    }

    if (source === "cash") {
      dispatch(removeCashCardAttempt());
    } else {
      dispatch(removePicklebetCardAttempt());
    }
  };

export const defaultCode = "1STDEPOSITBONUS";

export const doLoadDefaultBonus =
  (claims?: Record<string, PromotionClaim> | PromotionClaim[]) =>
  (dispatch) => {
    // Get first deposit bonus claim
    const claim = (claims && extractPendingDepositBonusClaim(claims)) || null;

    if (!!claim) {
      dispatch(
        setBonusState({
          code: defaultCode,
          currency: claim.currency,
          active: true, // TODO: Is tracking this redundant now?
          amount: claim.amount,
        }),
      );

      dispatch(updateBonusCode(defaultCode));
    } else {
      dispatch(doClearBonusState());
    }
  };

export const doQueueLoadDefaultBonus = () => (dispatch, getState) => {
  const { userId } = getState().auth;

  // Watch for bonus to be registered before loading it.
  const claimsRef = collection(
    getFirestore(),
    `users`,
    userId,
    `promotionClaims`,
  );

  onSnapshot(claimsRef, (snapshot) => {
    const claims = snapshot.docs.map((doc) => doc.data());
    // Note: assuming only one pending deposit bonus claim.
    dispatch(doLoadDefaultBonus(claims[0]));
  });
};

export const doClearBonusState = () => (dispatch) => {
  dispatch(setBonusState(null));
  dispatch(updateBonusCode(""));
};

export const doWithdrawInterac =
  (interacEmail, amount, setErrors, callback) => async (dispatch, getState) => {
    const requestId = "withdraw";
    dispatch(requestStarted(GROUP_WALLET, requestId));

    setErrors({});

    const { sessionId, userId } = getState().auth;

    try {
      const interacResponse = await processWithdraw(
        "interac",
        userId,
        sessionId,
        amount,
        { email: interacEmail },
      );

      if (interacResponse.success) {
        toast({
          title: "Success!",
          variant: "success",
          description:
            "Your withdrawal will be processed by 6pm EST, on the next available business day.",
        });
      } else {
        const errorMsg =
          interacResponse.errorMessage || "Unable to withdraw funds";
        throw new Error(errorMsg);
      }

      dispatch(
        withdrawCashAttempt({
          currency: "CAD",
          amount: amount,
          outcome: "success",
        }),
      );
    } catch (error) {
      if (hasErrors(error)) {
        const { message, errors, messageTemplate } = error;
        if (errors && Object.keys(errors).length > 0) {
          setErrors(error.errors);
        } else {
          setErrors({ message, messageTemplate });
        }
      } else {
        toast(
          convertErrorToToast(error, {
            title: "Unable to withdraw funds!",
          }),
        );
      }
    } finally {
      if (callback && callback instanceof Function) {
        callback();
      }
    }

    dispatch(requestFinished(requestId));
  };

export const generatePayID = () => async () => {
  const [_, error] = await message("generate-user-pay-id", {});

  if (error) {
    toast(convertErrorToToast(error));
    return false;
  }

  return true;
};

export const doGetDepositLimits = (callback: () => void) => async () => {
  await message("get-deposit-limits", {});

  if (callback && typeof callback === "function") {
    callback();
  }
};

export const {
  addCashCardAttempt,
  addPicklebetCardAttempt,
  removeCashCardAttempt,
  removePicklebetCardAttempt,
  depositFundsAttempt,
  depositFundsFailure,
  firstTimeDeposit,
  attemptRemoveBankAccount,
  withdrawCashAttempt,
  addedBankDetails,
  buySkrAttempt,
  attemptRegisterAddress,
  updateBonusCode,
  setBonusState,
  setSkrAccount,
  cancelWithdrawal,
  setAppleDepositStatus,
  setGoogleDepositStatus,
  setBankLoading,
  setIsApplePayAvailable,
  setIsGooglePayAvailable,
  toggleManual3ds,
  closeManual3ds,
  openManual3ds,
} = walletSlice.actions;

export const allActions = walletSlice.actions;

export default walletSlice.reducer;
