import { useEffect, useState } from "react";
import { navigate, navigateBack } from "library/components/Link";
import {
  PRIMARY_MESSAGE_TYPES,
  APP_TO_WEB_MESSAGE_TYPES,
  sendAppMessage,
  WEB_TO_APP_MESSAGE_TYPES,
} from "./";
import localObject from "utilities/localObject";
import { ATTRIBUTION_KEY } from "appConfig";
import * as Sentry from "@sentry/gatsby";

import {
  doMWDeposit,
  setAppleDepositStatus,
  setGoogleDepositStatus,
  setIsApplePayAvailable,
  setIsGooglePayAvailable,
  handle3dsChallengeIframe,
  handlePostMwFingerPrintIframe,
} from "sections/Wallet/walletSlice";
import { getState, dispatchNow } from "store";
import { titleCase } from "title-case";
import { setMenu } from "utilities/UI/uiSlice";
import { toast } from "hooks/ui/useToast";
import { type InAppMessageType, setCards, showCustomIAM } from "store/Braze";

export const AppToWebEvents = () => {
  const [isWebReady, setIsWebReady] = useState(false);

  const ProcessIncomingAppMessage = (e: CustomEvent) => {
    const detail = e?.detail;
    switch (detail?.type) {
      case APP_TO_WEB_MESSAGE_TYPES.APP_TO_WEB_MESSAGE_NAVIGATE:
        navigate(detail.payload.url);
        break;
      case APP_TO_WEB_MESSAGE_TYPES.APP_TO_WEB_MESSAGE_BACK: {
        const hudFocus = getState().utilities.ui.hudFocus;
        if (hudFocus === "panel" || hudFocus === "menu") {
          dispatchNow(setMenu("none"));
        } else {
          navigateBack();
        }
        break;
      }
      case APP_TO_WEB_MESSAGE_TYPES.APP_TO_WEB_MESSAGE_SET_ATTRIBUTION: {
        const payload = detail.payload as Record<string, string>;
        let [attribution] = localObject.getValue(ATTRIBUTION_KEY, {});

        // in case browser did not return valid data make sure we fall back to default value
        if (typeof attribution === "undefined") attribution = {};

        Object.entries(payload).forEach(([key, value]) => {
          attribution[key] = value;
        });

        localObject.setValue(ATTRIBUTION_KEY, attribution);
        break;
      }
      case APP_TO_WEB_MESSAGE_TYPES.APP_TO_WEB_MESSAGE_APPLE_PAY_TOKEN:
        dispatchNow(
          doMWDeposit(
            null,
            null,
            detail.payload.digitalWalletToken,
            detail.payload.amount,
            "",
            "Apple",
          ),
        ).then((outcome) => {
          sendAppMessage(
            WEB_TO_APP_MESSAGE_TYPES.APP_MESSAGE_FINISH_APPLE_PAY,
            {
              outcome,
            },
          );

          if (outcome === "success") {
            dispatchNow(setAppleDepositStatus("success"));
            return;
          }

          dispatchNow(setAppleDepositStatus("ready"));
        });

        break;
      case APP_TO_WEB_MESSAGE_TYPES.APP_TO_WEB_MESSAGE_APPLE_PAY_AVAILABLE:
        dispatchNow(setIsApplePayAvailable(detail.payload));
        break;
      case APP_TO_WEB_MESSAGE_TYPES.APP_TO_WEB_MESSAGE_GOOGLE_PAY_AVAILABLE:
        dispatchNow(setIsGooglePayAvailable(detail.payload));
        break;
      case APP_TO_WEB_MESSAGE_TYPES.APP_TO_WEB_MESSAGE_SENTRY_LOG:
        try {
          const { message, extra } = detail.payload;
          Sentry.captureEvent({
            message: message,
            level: "error",
            extra,
          });
        } catch (e) {
          console.log(e);
        }
        break;
      case APP_TO_WEB_MESSAGE_TYPES.APP_TO_WEB_MESSAGE_GOOGLE_PAY_TOKEN:
        if (detail.payload.error) {
          toast({
            description: titleCase(detail.payload.error.message),
            variant: "danger",
          });
          dispatchNow(setGoogleDepositStatus("ready"));
        } else {
          dispatchNow(
            doMWDeposit(
              null,
              null,
              JSON.parse(detail.payload.digitalWalletToken),
              detail.payload.amount,
              "",
              "Google",
            ),
          ).then((outcome) => {
            // notify redux that deposit was successful
            if (outcome === "success") {
              dispatchNow(setGoogleDepositStatus("success"));
              return;
            }

            dispatchNow(setGoogleDepositStatus("ready"));
          });
        }
        break;
      case APP_TO_WEB_MESSAGE_TYPES.APP_TO_WEB_MESSAGE_CUSTOM_SLIDEUP_IAM:
        showCustomIAM(detail.payload as InAppMessageType);
        break;
      case APP_TO_WEB_MESSAGE_TYPES.APP_TO_WEB_MESSAGE_BRAZE_CONTENT_CARDS:
        // App Cards have slight difference in shape compared to Web Cards. We need to map the image property to imageUrl

        dispatchNow(
          setCards(
            detail.payload?.map((card: any) => ({
              ...card,
              expiresAt:
                // Unix time from the App. Negative values never expire (Apps Only???)
                card.expiresAt && card.expiresAt > 0
                  ? new Date(card.expiresAt * 1000)
                  : undefined,

              imageUrl: card.image,
            })),
          ),
        );
        break;
      case APP_TO_WEB_MESSAGE_TYPES.APP_TO_WEB_MESSAGE_MW3DS_CHALLENGE_READY:
        handle3dsChallengeIframe(detail.payload.creq, detail.payload.acsUrl);
        break;
      case APP_TO_WEB_MESSAGE_TYPES.APP_TO_WEB_MESSAGE_MW3DS_FINGERPRINT_READY:
        handlePostMwFingerPrintIframe(
          detail.payload.paReq,
          detail.payload.acsUrl,
        );
        break;
      default:
        break;
    }

    if (!isWebReady) setIsWebReady(true);
  };

  // tell the apps that we're ready to receive messages
  useEffect(() => {
    if (isWebReady) {
      sendAppMessage(WEB_TO_APP_MESSAGE_TYPES.APP_MESSAGE_WEB_IS_READY, {});
    }
  }, [isWebReady]);

  useEffect(() => {
    document.addEventListener(
      PRIMARY_MESSAGE_TYPES.APP_TO_WEB_MESSAGE,
      ProcessIncomingAppMessage,
    );

    return () => {
      document.removeEventListener(
        PRIMARY_MESSAGE_TYPES.APP_TO_WEB_MESSAGE,
        ProcessIncomingAppMessage,
      );
    };
  }, []);

  return null;
};
