import React, { useEffect, useMemo, useState } from "react";
import { CurrencyInput, QuickInput } from "components/CurrencyInput";
import { Button } from "components/Button";
import {
  toast,
  useApplePay,
  useBetslip,
  useDepositLimits,
  useDispatch,
  useFiatCurrency,
  useIsApplePayAvailable,
  useIsGooglePayAvailable,
  useWalletActions,
} from "hooks";
import { usePromotionClaims } from "hooks/firestore/v2/user/usePromotionClaims";
import { useCreditCardWithDeposit } from "hooks/firestore/v2/user/useCreditCards";
import { BetslipUIStates } from "sections/Betting/Betslip/betslipSlice";
import { Options } from "./Options";
import { QuickDepositOption } from "./types";
import type { DepositOutcomeTypes } from "sections/Wallet/walletSlice";
import { SlideOut } from "../SlideOut";
import { validateDepositAmount } from "utilities/Wallet/walletUtilities";
import { ReactComponent as ChevronDown } from "assets/icons/arrow-down-1.svg";
import { GooglePayButton } from "components/layout/GooglePayButton";
import { useLocalStorage } from "usehooks-ts";
import { constructCurrency } from "common";

import * as styles from "./QuickDeposit.module.scss";
import {
  calculateDepositBonusAmount,
  extractPendingDepositBonusClaim,
} from "types/PromotionTypes";

type QuickDepositProps = {
  requiredDepositAmount: number;
};

const QuickDeposit = ({ requiredDepositAmount }: QuickDepositProps) => {
  const dispatch = useDispatch();
  const {
    props: { betslipIsActive },
    actions: { setBetSlipState },
  } = useBetslip();
  const currency = useFiatCurrency();
  const isApplePayAvailable = useIsApplePayAvailable();
  const isGooglePayAvailable = useIsGooglePayAvailable();
  const creditCard = useCreditCardWithDeposit();
  const [selectedOption, setSelectedOption] =
    useLocalStorage<QuickDepositOption>(
      "quickDepositLastSelectedOption",
      QuickDepositOption.CREDITDEBIT,
    );
  const [limit, limitsLoading] = useDepositLimits(currency);
  const [hasFocus, setHasFocus] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { doMWDeposit } = useWalletActions();
  const depositWithApplePay = useApplePay();
  const { claims } = usePromotionClaims();
  const pendingDepositClaim = extractPendingDepositBonusClaim(claims);

  const [depositAmount, setDepositAmount] = useState<number>(
    requiredDepositAmount,
  );

  useEffect(() => {
    if (requiredDepositAmount <= 0 && !betslipIsActive) {
      dispatch(setBetSlipState(BetslipUIStates.active));
    }
  }, [requiredDepositAmount, betslipIsActive]);

  useEffect(() => {
    if (requiredDepositAmount > 0 && requiredDepositAmount < depositAmount) {
      // if required amount changed, e.g. deposit happened, try to match the value
      setDepositAmount(requiredDepositAmount);
    }
  }, [requiredDepositAmount]);

  useEffect(() => {
    if (limitsLoading) {
      return;
    }

    // if deposit amount is less than allowed minimum, set it to minimum
    if (requiredDepositAmount < limit?.minimumDepositAmount) {
      setDepositAmount(limit?.minimumDepositAmount);
    }
  }, [limitsLoading]);

  // NOTE: this is very ugly, underlying validation method returns string when something is off and true when everything is ok
  const isValid = validateDepositAmount(
    depositAmount / 100,
    limit,
    limitsLoading,
    currency,
    true,
  );
  // I've expanded this logic to be more readable
  const hasValidationErrors = isValid !== true;
  const errorMessage = hasValidationErrors ? isValid : null;

  const availableDepositOptions = useMemo(() => {
    const options: QuickDepositOption[] = [];

    if (creditCard) {
      options.push(QuickDepositOption.CREDITDEBIT);
    }

    if (isGooglePayAvailable) {
      options.push(QuickDepositOption.GOOGLEPAY);
    }

    if (isApplePayAvailable) {
      options.push(QuickDepositOption.APPLEPAY);
    }

    // other is always available
    options.push(QuickDepositOption.OTHER);

    return options;
  }, [!!creditCard, isGooglePayAvailable, isApplePayAvailable]);

  const process = async () => {
    // 1. determine which payment method to use
    // 2. call the payment method
    // 3. if successful show notification

    if (hasValidationErrors) return;

    const onSuccess = (outcome: DepositOutcomeTypes) => {
      if (outcome === "success") {
        toast({
          title: "Success!",
          description: "Deposit received.",
          variant: "success",
        });

        dispatch(setBetSlipState(BetslipUIStates.active));
      }
    };

    if (selectedOption === QuickDepositOption.CREDITDEBIT) {
      return await doMWDeposit(
        creditCard?.id,
        null,
        null,
        depositAmount / 100,
        "",
        null,
        onSuccess,
      );
    }

    if (selectedOption === QuickDepositOption.APPLEPAY) {
      return depositWithApplePay(depositAmount / 100, () =>
        onSuccess("success"),
      );
    }

    // NOTE: there is nothing to do for Google Pay, as it is handled by the GooglePayButton component
    // logic there is self-contained
  };

  return (
    <SlideOut onClose={() => dispatch(setBetSlipState(BetslipUIStates.active))}>
      <div className={styles.title}>Quick Deposit</div>
      <Options
        cardNumberFirst={creditCard?.numberFirst}
        cardNumberLast={creditCard?.numberLast}
        options={availableDepositOptions}
        setSelectedOption={setSelectedOption}
        selectedOption={
          availableDepositOptions.includes(selectedOption)
            ? selectedOption
            : availableDepositOptions[0]
        }
      />
      <div className={styles.inputContainer}>
        <CurrencyInput
          currency={currency}
          label="Deposit Amount"
          placeholder="Enter deposit amount"
          containerClassName={styles.input}
          value={depositAmount / 100}
          onChange={(amount) => setDepositAmount(amount * 100)}
          onBlur={() => setHasFocus(false)}
          onFocus={() => setHasFocus(true)}
          errorMessage={errorMessage}
          hasError={hasValidationErrors}
        />
        <QuickInput.Root
          isVisible={hasFocus}
          className={styles.quickInput}
          currencySymbol={`$`}
          size={"sm"}
        >
          <QuickInput.Option
            onChange={(value) => setDepositAmount(depositAmount + value * 100)}
            value={5}
          />
          <QuickInput.Option
            onChange={(value) => setDepositAmount(depositAmount + value * 100)}
            value={10}
          />
          <QuickInput.Option
            onChange={(value) => setDepositAmount(depositAmount + value * 100)}
            value={50}
          />
          <QuickInput.Option
            onChange={(value) => setDepositAmount(depositAmount + value * 100)}
            value={100}
          />
          <QuickInput.Option
            onChange={(value) => setDepositAmount(value)}
            value={requiredDepositAmount}
            label={`Req`}
          />
        </QuickInput.Root>
      </div>
      <div className={styles.footer}>
        <Button
          onClick={() => dispatch(setBetSlipState(BetslipUIStates.active))}
          variant={`blurred`}
          className={styles.iconButton}
        >
          <ChevronDown />
        </Button>
        {/* NOTE: we can't use Google Pay without their button, so it's placed instead of generic button */}
        {selectedOption === QuickDepositOption.GOOGLEPAY ? (
          <GooglePayButton
            loading={limitsLoading}
            isFinal={!hasValidationErrors}
            amount={depositAmount / 100}
            onValidationError={(onValidationError) => {
              if (hasValidationErrors) {
                onValidationError();
              }
            }}
            size={`small`}
          />
        ) : (
          <Button
            loading={isProcessing}
            variant={`info`}
            className={styles.button}
            onClick={async () => {
              setIsProcessing(true);
              await process();
              setIsProcessing(false);
            }}
          >
            Deposit{` `}
            {constructCurrency(depositAmount, {
              currency,
            })}
          </Button>
        )}
        {pendingDepositClaim && depositAmount > 0 ? (
          <div className={styles.bonus}>
            {" +" +
              calculateDepositBonusAmount(
                depositAmount / 100,
                pendingDepositClaim,
              ) +
              " "}
            <span className={styles.badge}>bonus</span>
          </div>
        ) : null}
      </div>
    </SlideOut>
  );
};

export { QuickDeposit };
